
import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { addUser, editUser, getRoleList } from './api'
import { noBlank, RangeLimit, required, xceptChinese } from '@/utils/rules'
export default defineComponent({
  props: {
    visible: Boolean,
    title: String,
    rowData: Object
  },
  setup(props, { emit }) {
    const data = reactive({
      ruleForm: null,
      isDialog: props.visible,
      formData: props.rowData,
      departmentList: [],
      roleList: []
    })
    data.formData.roleId =
      data.formData.roleStatus === 1 ? data.formData.roleId : ''

    const rules = {
      nickName: [required],
      mobile: [
        required,
        {
          pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
          message: '请输入正确手机号',
          trigger: 'blur'
        }
      ],
      password: [required, RangeLimit(8, 12), xceptChinese, noBlank],
      departmentId: [required],
      roleId: [required],
      userStatus: [required]
    }

    // 关闭弹框
    const handleCloseDialog = () => {
      emit('close-dialog', false)
    }
    // 提交表单
    const submitDialog = () => {
      data.ruleForm.validate((valid: boolean) => {
        if (valid) {
          const params: any = {
            userName: data.formData.nickName,
            mobile: data.formData.mobile,
            password: data.formData.password,
            roleId: data.formData.roleId,
            userStatus: data.formData.userStatus
          }
          let methodFn = addUser
          if (data.formData.id) {
            params.id = data.formData.id
            methodFn = editUser // 修改方法
          }

          methodFn(params).then((res: any) => {
            ElMessage.success(res.msg)
            handleCloseDialog()
            emit('getListFn')
          })
        }
      })
    }

    return {
      ...toRefs(data),
      rules,
      handleCloseDialog,
      submitDialog
    }
  },
  mounted() {
    this.getRoleList()
  },
  methods: {
    getRoleList() {
      // 获取角色
      getRoleList({ pageSize: 9999 }).then(res => {
        res.data.list.forEach(item => {
          this.roleList.push({
            label: item.roleName,
            value: item.roleId
          })
        })
      })
    }
  }
})
