import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main-bg" }
const _hoisted_2 = { class: "opebtn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_search_list = _resolveComponent("c-search-list")
  const _component_el_button = _resolveComponent("el-button")
  const _component_c_table = _resolveComponent("c-table")
  const _component_modify_dialog = _resolveComponent("modify-dialog")
  const _component_log_dialog = _resolveComponent("log-dialog")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_c_search_list, {
      inputList: _ctx.inputList,
      onOnSearch: _ctx.handleSearch,
      onOnReset: _ctx.handleReset
    }, null, 8, ["inputList", "onOnSearch", "onOnReset"]), [
      [_directive_hasAuth, 'user-manager-query']
    ]),
    _createVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.btnHandle('add',null)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnName('user-manager-add') ||'新建用户'), 1)
        ]),
        _: 1
      }, 512), [
        [_directive_hasAuth, 'user-manager-add']
      ])
    ]),
    _createVNode(_component_c_table, {
      dataSource: _ctx.tableConfig,
      onPageCurrentChange: _ctx.pageCurrentChange,
      onPageSizeChange: _ctx.pageSizeChange
    }, {
      action: _withCtx((scope) => [
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (_ctx.btnHandle('edit', scope.row))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnName('user-manager-edit') ||'修改'), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'user-manager-edit']
        ]),
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (_ctx.btnHandle('del', scope.row))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnName('user-manager-del') ||'删除'), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'user-manager-del']
        ]),
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (_ctx.btnHandle('log', scope.row))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnName('user-manager-log') ||'操作日志'), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'user-manager-log']
        ])
      ]),
      _: 1
    }, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"]),
    (_ctx.visible)
      ? (_openBlock(), _createBlock(_component_modify_dialog, {
          key: 0,
          title: _ctx.title,
          visible: _ctx.visible,
          "row-data": _ctx.rowData,
          onCloseDialog: _ctx.closeDialog,
          onGetListFn: _ctx.getListFn
        }, null, 8, ["title", "visible", "row-data", "onCloseDialog", "onGetListFn"]))
      : _createCommentVNode("", true),
    (_ctx.logVisible)
      ? (_openBlock(), _createBlock(_component_log_dialog, {
          key: 1,
          visible: _ctx.logVisible,
          id: _ctx.id,
          onCloseDialog: _ctx.closeDialog
        }, null, 8, ["visible", "id", "onCloseDialog"]))
      : _createCommentVNode("", true)
  ]))
}