
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { defineComponent, reactive, toRefs } from 'vue'
import { getTableConfig, getIndexConfig } from './log-config'
import {
  getPageOfLog,
  getListOfOperationMenu,
  getListOfOperationType
} from './api'
import moment from 'moment'

export default defineComponent({
  components: { CSearchList, CTable },
  props: {
    visible: Boolean,
    id: String
  },
  setup(props, { emit }) {
    const tableConfig = reactive({
      ...getTableConfig()
    })
    const data = reactive({
      ...getIndexConfig(),
      isDialog: props.visible
    })

    // 关闭弹框
    const handleCloseDialog = () => {
      emit('close-dialog', false)
    }

    // 列表数据
    const getListFn = () => {
      const params = {
        accountId: props.id,
        currPage: data.currPage,
        pageSize: data.pageSize
      }
      const obj = Object.assign(params, data.queryForm)
      tableConfig.loading = true
      getPageOfLog(obj)
        .then(res => {
          tableConfig.data = res.data.list
          tableConfig.data.map(item => {
            item.updateTime = moment(item.updateTime).format(
              'YYYY-MM-DD HH:mm:ss'
            )
            item.roleStatus = item.roleStatus ? '正常' : '禁用'
          })
          tableConfig.pagination.total = res.data.total
        })
        .finally(() => {
          tableConfig.loading = false
        })
    }

    getListFn()

    // 表单设置页数
    const pageSizeChange = (val: number) => {
      data.pageSize = val
      getListFn()
    }

    // 表单翻页
    const pageCurrentChange = (val: number) => {
      data.currPage = val
      getListFn()
    }

    // 执行搜索
    const handleSearch = (params: object) => {
      data.queryForm = params
      getListFn()
    }

    // 执行重置
    const handleReset = (params: object) => {
      data.queryForm = params
    }

    // 搜索已授权角色
    data.inputList.forEach(item => {
      if (item.key === 'operationMenu') {
        const arr = []
        getListOfOperationMenu(null).then((res: any) => {
          res.data.forEach(item => {
            arr.push({
              label: item.operationMenu,
              value: item.operationMenu
            })
          })
          item.options = arr
        })
      } else if (item.key === 'operationType') {
        const arr = []
        getListOfOperationType(null).then((res: any) => {
          res.data.forEach(item => {
            arr.push({
              label: item.operateType,
              value: item.operateType
            })
          })
          item.options = arr
        })
      }
    })

    return {
      tableConfig,
      ...toRefs(data),
      pageSizeChange,
      pageCurrentChange,
      handleSearch,
      handleReset,
      handleCloseDialog
    }
  }
})
